import React from 'react';
import { faBalanceScale, faBook, faBookReader, faBuilding, faChalkboardTeacher, faCompass, faGlobeAmericas,faSchool, faTv } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import lefLanding from '../img/lef2.png';
import jalisco from '../img/jalisco.png';
import libros from '../img/books.png';
import entrega from '../img/delivery.png';
import paquete from '../img/paquete.png';


const Lef = () => {
  return (
    <main role="main" className="min-vh-100">
      <img
        src={lefLanding}
        className="img-fluid w-100 mt-5"
        alt="LEF"
      />
      <div className="container-lg text-dark">
        <div className="row justify-content-around mt-5">
          <div className="bg-secondary col-xl-3 col-lg-3 col-md-3 col-sm-6 col-8 m-3 p-3 rounded">
          <div className={"bg-secondary rounded text-primary text-center mb-2"}>
            <img
              src={jalisco}
              className="img-fluid w-50 mx-auto"
              alt="LEF"
            />
            <h5 className="text-dark pt-5">
              Las escuelas que han adoptado el programa LEF se encuentran
              principalmente en el estado de Jalisco.
            </h5>
            </div>
          </div>
          <div className="bg-secondary col-xl-3 col-lg-3 col-md-3 col-sm-6 col-8 m-3 p-3 rounded">
          <Cuadroconicon
              icon={faBookReader}
              nombre=" Nuestra finalidad es fomentar el hábito de la lectura, mejorar
              los niveles educativos y disminuir la inasistencia-abandono de
              estudios de los alumnos."
              claseDiv="text-center mb-2"
              size="7x"
              clase="pt-5"
            />
          </div>
          <div className="bg-secondary col-xl-3 col-lg-3 col-md-3 col-sm-6 col-8 m-3 p-3 rounded">
          <Cuadroconicon
              icon={faChalkboardTeacher}
              nombre="Para que LEF funcione, los maestros son nuestros aliados en este programa."
              claseDiv="text-center mb-2"
              size="7x"
              clase="pt-5"
            />
          </div>
        </div>
        <div className="row justify-content-center text-center">
          <h3 className="text-center col-12 text-dark my-5">
            Principales escuelas participantes<br></br>
          </h3>
          <Cuadroconicon
            icon={faSchool}
            nombre="Secundarias"
            clase="pt-3"
            claseDiv="col-xl-3 col-lg-3 col-md-3 col-sm-5 col-5 m-3 p-3"
            size="7x"
          />
          <Cuadroconicon
            icon={faTv}
            nombre="Telesecundarias"
            clase="pt-3"
            claseDiv="col-xl-3 col-lg-3 col-md-3 col-sm-5 col-5 m-3 p-3"
            size="7x"
          />
          <Cuadroconicon
            icon={faBuilding}
            nombre="Preparatorias"
            clase="pt-3"
            claseDiv="col-xl-3 col-lg-3 col-md-3 col-sm-5 col-5 m-3 p-3"
            size="7x"
          />
          <p className="text-dark">
            ¡No te preocupes, todas las escuelas pueden participar!. Juntos
            podemos encontrar la estrategia perfecta para adaptar LEF.
          </p>
        </div>
        <div className="row justify-content-center text-center">
          <h3 className="text-dark col-12 my-5">
            Desarrollado en función de la Reforma Educativa que plantea la
            aplicación de materiales didácticos de manera transversal.<br></br>
            Es decir, los libros pueden ser aplicados en diversas materias como:
          </h3>
          <Cuadroconicon
            icon={faBook}
            nombre="Español"
            clase="pt-3"
            claseDiv="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-4 m-3 p-2"
            size="5x"
          />
          <Cuadroconicon
            icon={faGlobeAmericas}
            nombre="Geografía"
            clase="pt-3"
            claseDiv="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-4 m-3 p-2"
            size="5x"
          />
          <Cuadroconicon
            icon={faCompass}
            nombre="Historia"
            clase="pt-3"
            claseDiv="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-4 m-3 p-2"
            size="5x"
          />
          <Cuadroconicon
            icon={faBalanceScale}
            nombre="Formación Cívica y Ética"
            claseDiv="col-xl-2 col-lg-2 col-md-2 col-sm-4 col-4 m-3 p-2"
            size="5x"
          />
        </div>
        <div className="row justify-content-center text-dark">
          <h2 className="col-12 text-center text-dark my-5">¿Cómo funciona?</h2>
          <div className="row my-3">
            <div className="col bg-secondary d-flex justify-content-center">
              <img
                src={libros}
                className="img-fluid w-50"
                alt="LEF"
              />
            </div>
            <div className="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7 d-flex align-items-center d-flex justify-content-center">
              <h4>Por cada 5 libros vendidos, donamos 1 para LEF.</h4>
            </div>
          </div>
          <div className="row my-3">
            <div className="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7 d-flex align-items-center d-flex justify-content-center">
              <h4>
                Cuando se completan los paquetes son entregados a las escuelas participantes.
              </h4>
            </div>
            <div className="col bg-secondary d-flex justify-content-center">
              <img
                src={entrega}
                className="img-fluid w-50"
                alt="LEF"
              />
            </div>
          </div>
          <div className="row my-3">
            <div className="col bg-secondary d-flex justify-content-center">
              <img
                src={paquete}
                className="img-fluid w-50"
                alt="LEF"
              />
            </div>
            <div className="col-7 d-flex align-items-center d-flex justify-content-center">
              <h4>Un paquete contiene 10 ejemplares</h4>
            </div>
          </div>
        </div>
        <h3 className="my-5 text-center text-dark h2">¿Te interesa?</h3>
        <div className="row vh-50">
          <div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 col-12 d-flex row align-items-center text-center text-dark align-content-center">
            <p className="h3">
              Registrate aquí y pronto nos comunicaremos contigo.
            </p>
            <p className="h5">
              Para más información comunicate al correo electrónico:
              <a href="mailto:lef@beatrizfuentes.com" className="text-dark">
                {" "}
                lef@beatrizfuentes.com
              </a>
            </p>
          </div>
          <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 col-12">
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSf8m7LosMYcTkK8L3qLrF4bbHgxGxB3WBGoCP_rKU8AqAqe1Q/viewform?embedded=true"
              className="w-100 container"
              height="400"
              title="Lef"
            >
              Cargando…
            </iframe>
          </div>
        </div>
      </div>
    </main>
  );
};

function Cuadroconicon({ icon, nombre, clase, claseDiv, size }) {
  return (
    <div className={"bg-secondary rounded text-primary " + claseDiv}>
      <FontAwesomeIcon icon={icon} size={size} />
      <h5 className={"text-dark " + clase}>{nombre}</h5>
    </div>
  );
}

export default Lef;